<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine">
      <div class="common-plate-title">
        <span>{{ $trans('设置', '设置') }}</span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="setting-function-item flex-bet" style="margin-bottom: 20px">
        <span class="setting-item-name">
          {{ $trans('我的头像', '我的头像') }}
        </span>
        <span class="setting-item-right flex-cen">
          <span class="setting-item-proto" @click="avatarDialogVisible = true">
            <img
              :src="
                data.avatar ? data.avatar : '@/assets/img/defaultAvatar.jpg'
              "
              alt=""
            />
          </span>
          <i class="iconfont icon-xiangyou1"></i>
        </span>
      </div>
      <div
        @click="changeNickName"
        class="setting-function-item flex-bet line-bottom"
      >
        <span class="setting-item-name">
          {{ $trans('修改昵称', '修改昵称') }}
        </span>
        <span class="setting-item-right flex-cen">
          <span class="setting-item-content">
            {{ data.nickName }}
          </span>
          <i class="iconfont icon-xiangyou1"></i>
        </span>
      </div>
      <div
        @click="changeSex"
        class="setting-function-item flex-bet line-bottom"
      >
        <span class="setting-item-name"> {{ $trans('性别', '性别') }} </span>
        <span class="setting-item-right flex-cen">
          <span class="setting-item-content">
            {{ data.sex == 1 ? '男' : data.sex == 0 ? '女' : '未知' }}
          </span>
          <i class="iconfont icon-xiangyou1"></i>
        </span>
      </div>
      <div
        @click="changeAge"
        class="setting-function-item flex-bet line-bottom"
      >
        <span class="setting-item-name"> {{ $trans('年龄', '年龄') }} </span>
        <span class="setting-item-right flex-cen">
          <span class="setting-item-content">
            {{ data.age }}
          </span>
          <i class="iconfont icon-xiangyou1"></i>
        </span>
      </div>
      <!-- <div @click="toSettingTel" class="setting-function-item flex-bet line-bottom">
            <span class="setting-item-name">
                修改手机号
            </span>
            <span class="setting-item-right flex-cen">
                <span class="setting-item-content">
                    {{data.phone }}
                </span>
                <i class="iconfont icon-xiangyou1"></i>
            </span>
        </div> -->
      <div
        @click="authenticationHandle"
        class="setting-function-item flex-bet line-bottom"
      >
        <span class="setting-item-name">
          {{ $trans('实名认证', '实名认证') }}
        </span>
        <span class="setting-item-right flex-cen">
          <span class="setting-item-content">
            {{ data.status == 3 ? '已实名' : '未实名' }}
          </span>
          <i class="iconfont icon-xiangyou1"></i>
        </span>
      </div>
      <div
        @click="$router.history.push('/setting/email')"
        class="setting-function-item flex-bet line-bottom"
      >
        <span class="setting-item-name">
          {{ $trans('绑定邮箱', '绑定邮箱') }}
        </span>
        <span class="setting-item-right flex-cen">
          <span class="setting-item-content">
            {{ data.email }}
          </span>
          <i class="iconfont icon-xiangyou1"></i>
        </span>
      </div>
      <div class="btn-fill" @click="logoutHandle">
        {{ $trans('退出登录', '退出登录') }}
      </div>
      <el-dialog
        :title="$trans('修改用户信息', '修改用户信息')"
        :visible.sync="sexDialogVisible"
        width="50%"
        top="30vh"
      >
        <el-select v-model="sexValue" :placeholder="$trans('请选择', '请选择')">
          <el-option :label="$trans('男', '男')" value="1" />
          <el-option :label="$trans('女', '女')" value="0" />
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="sexDialogVisible = false">
            {{ $trans('取消', '取 消') }}</el-button
          >
          <el-button type="primary" @click="confirmHandle">
            {{ $trans('确定', '确 定') }}</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        :title="$trans('修改用户信息', '修改用户信息')"
        :visible.sync="avatarDialogVisible"
        width="60%"
      >
        <!-- action="https://api.shangshuroom.com/api/common/upload/qiniu/avatar" -->
        <el-upload
          class="avatar-uploader"
          action="https://api.shangshuroom.com/api/common/upload/qiniu/avatar"
          :on-success="handleAvatarSuccess"
          :headers="{ token }"
          :limit="1"
          :on-exceed="overstepAvatarUpload"
          :multiple="false"
          :drag="true"
          list-type="picture-card"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="avatarDialogVisible = false">{{
            $trans('取消', '取 消')
          }}</el-button>
          <el-button type="primary" @click="avatarHandle">{{
            $trans('确定', '确 定')
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getUserInfo, revampUserInfo } from '@/util/js/api.js'
import { cookieMethods } from '@/util/js/methods.js'
export default {
  name: '',
  data() {
    return {
      data: {},
      sexValue: '',
      sexDialogVisible: false,
      avatarDialogVisible: false,
      avatarValue: '',
      imageUrl: '',
      imageUrlUpload: '',
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    // 跳转页面
    toSettingTel() {
      this.$router.history.push({
        path: '/setting/tel',
        query: {
          data: this.data,
        },
      })
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo({ userId: this.$store.state.userId }).then((res) => {
        if (res.code == 200) {
          this.selfInfo = JSON.parse(JSON.stringify(res.data))
          localStorage.removeItem('user')
          localStorage.setItem('user', JSON.stringify(res.data))
          this.data = JSON.parse(JSON.stringify(res.data))
        } else {
          if (JSON.stringify(res).includes('无效Token')) {
            this.logoutHandleMethods.call(this, '', '', '登录已过期,请重新登录')
          } else {
            this.$message.error(
              this.$trans(
                '获取用户信息失败,请重新刷新',
                '获取用户信息失败,请重新刷新'
              )
            )
          }
        }
      })
    },
    // 修改用户昵称
    changeNickName() {
      const title = this.$trans('修改用户信息', '修改用户信息')
      const secondeTitle = this.$trans('请输入用户昵称', '请输入用户昵称')
      this.$prompt(secondeTitle, title, {
        confirmButtonText: this.$trans('确定', '确定'),
        cancelButtonText: this.$trans('取消', '取消'),
        inputPattern: /^[a-zA-Z0-9\u4E00-\u9FA5]{2,6}$/,
        inputErrorMessage: '昵称格式不正确',
        center: true,
      })
        .then(({ value }) => {
          let params = {
            id: this.data.id,
            nickName: value,
          }
          revampUserInfo(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(
                this.$trans('修改用户信息成功', '修改用户信息成功')
              )
              this.getUserInfo()
            } else {
              this.$message.error(
                this.$trans('修改用户信息失败', '修改用户信息失败')
              )
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$trans('取消输入', '取消输入'),
          })
        })
    },
    // 修改年龄
    changeAge() {
      const title = this.$trans('修改用户信息', '修改用户信息')
      const secondeTitle = this.$trans('请输入用户年龄', '请输入用户年龄')
      this.$prompt(secondeTitle, title, {
        confirmButtonText: this.$trans('确定', '确定'),
        cancelButtonText: this.$trans('取消', '取消'),
        // inputPattern: /^(([0-9]|[1-9][1-9]|1[0-7][0-9])(\.[0-9]+)?|180)$/,
        inputPattern: /^([0-9])$|^([1-9][0-9])$|^(1[0-9]{2})$/,
        inputErrorMessage: '年龄格式不正确',
        inputType: 'Number',
        center: true,
      })
        .then(({ value }) => {
          let params = {
            id: this.data.id,
            age: value,
          }
          revampUserInfo(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(
                this.$trans('修改用户信息成功', '修改用户信息成功')
              )
              this.getUserInfo()
            } else {
              this.$message.error(
                this.$trans('修改用户信息失败', '修改用户信息失败')
              )
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$trans('取消输入', '取消输入'),
          })
        })
    },
    // 打开修改年龄框
    changeSex() {
      this.sexDialogVisible = true
    },
    // 确认信息
    confirmHandle() {
      let params = {
        id: this.data.id,
        sex: this.sexValue,
      }
      revampUserInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(
            this.$trans('修改用户信息成功', '修改用户信息成功')
          )
          this.getUserInfo()
        } else {
          this.$message.error(
            this.$trans('修改用户信息失败', '修改用户信息失败')
          )
        }
        this.sexValue = ''
      })
      this.sexDialogVisible = false
    },
    // 修改头像确认信息
    avatarHandle() {
      let params = {
        id: this.data.id,
        avatar: this.imageUrlUpload,
      }
      revampUserInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(
            this.$trans('修改用户信息成功', '修改用户信息成功')
          )
          this.getUserInfo()
        } else {
          this.$message.error(
            this.$trans('修改用户信息失败', '修改用户信息失败')
          )
        }
        this.avatarDialogVisible = false
        this.imageUrlUpload = ''
      })
    },
    // 上传头像之前
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error(
          this.$trans(
            '上传头像图片只能是 JPG 格式!',
            '上传头像图片只能是 JPG 格式!'
          )
        )
      }
      if (!isLt2M) {
        this.$message.error(
          this.$trans(
            '上传头像图片大小不能超过 5MB!',
            '上传头像图片大小不能超过 5MB!'
          )
        )
      }
      return isJPG && isLt2M
    },
    // 成功回调
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.imageUrlUpload = res.url
    },
    // 超出上传个数现在
    overstepAvatarUpload() {
      this.$message.warning(
        this.$trans('只可以上传一个图片', '只可以上传一个图片')
      )
    },
    // 实名认证
    authenticationHandle() {
      if (this.data.status == 1) {
        this.$router.history.push('/setting/authentication')
        return
      }
      return
    },
    // 退出登录
    logoutHandle() {
      this.logoutHandleMethods.call(this)
    },
  },
  computed: {
    token() {
      return cookieMethods.get('token')
    },
  },
}
</script>
<style scoped>
.common-gaine {
  width: 600px;
}
.setting-function-item {
  height: 100px;
  padding: 10px;
  align-items: flex-end;
}
.setting-item-name {
  font-size: 16px;
  font-family: 'myCoolFontRegular';
}
.setting-item-right {
  cursor: pointer;
}
.setting-item-proto {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  overflow: hidden;
}
.setting-item-content {
  color: #999999;
  font-size: 14px;
  font-family: 'myCoolFontRegular';
  line-height: 24px;
}
.btn-fill {
  cursor: pointer;
  width: 300px;
  margin: 63px auto 0;
}
.icon-xiangyou1 {
  font-size: 14px;
  margin-left: 10px;
}
.setting-item-proto img {
  height: 100%;
}
</style>
<style>
.el-dialog__wrapper {
  left: 25%;
}
.el-message-box__wrapper {
  left: 25%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
